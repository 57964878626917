<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="10">
        <h2>Payment History</h2>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row>
      <v-col cols="12" sm="10">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="paymentHistory"
            :loading="loading"
            class="elevation-1"
            v-if="paymentHistory"
          >
            <!-- buyer avatar -->
            <template v-slot:item.orderDate="{ item }">{{
              timeCreated(item.orderDate)
            }}</template>

            <!-- order id -->
            <template v-slot:item.orderId="{ item }">
              <router-link
                :to="'/order/view/' + item.orderId + '?source=seller'"
                >{{ item.orderId }}</router-link
              >
            </template>

            <!-- order amount -->
            <template v-slot:item.amount="{ item }">
              <span dark class="green">{{
                showAmount(item.amount) | currency
              }}</span>
            </template>

            <!-- order status -->
            <template v-slot:item.status="{ item }">{{
              getStatus(item.status, item.refundAmount)
            }}</template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      headers: [
        { text: 'Amount', value: 'amount' },
        { text: 'Order', value: 'orderId' },
        { text: 'Status', value: 'status' },
        { text: 'Date', value: 'orderDate' }
      ]
    }
  },
  mounted() {
    // already checked in parent
    this.$store.dispatch('checkAccount', { charges: true })
    this.$store.dispatch('getCharges')
    this.$store.dispatch('getPaymentHistory')
  },
  computed: {
    paymentHistory() {
      return this.$store.getters.paymentHistory
    },
    loading() {
      return this.$store.getters.loading
    }
  },
  methods: {
    showAmount(amount) {
      let a = parseInt(amount) / 100.0
      return a.toLocaleString()
    },
    timeCreated(time) {
      if (time) {
        return moment.unix(time / 1000).format('HH:mm MM/DD/YY')
      } else {
        return 'N/A'
      }
    },
    getStatus(status, refund) {
      if (refund && refund > 0) {
        return 'refunded'
      }
      return status
    }
  }
}
</script>
